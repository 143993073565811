import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from '../components/seo'
import { AllOtherProducts } from "../graphql/OtherSmallQueries"
import dot from "../images/dotMany.png"
import { OthersText } from "../graphql/QueryById"

const OtherProducts = () => {
  const products = AllOtherProducts()
  const [value, setValue] = useState(0)
  const productLength = products.length

  const content = products.map((item, indexContent) => {
    const { countryName, flag, image, md, menuHide, name } = item ?? undefined
    return (
      <div
        key={indexContent}
        className="relative z-10 md:bg-white md:z-20 md:p-10 md:rounded-md"
        style={{
          WebkitBackfaceVisibility: "hidden",
          MozBackfaceVisibility: "hidden",
          WebkitTransform: "translate3d(0, 0, 0)",
          MozTransform: "translate3d(0, 0, 0)",
        }}
      >
        {md}
      </div>
    )
  })

  const productImageCount = products[value].images.length

  const productImages = products.map((item, indexProducts) => {
    return (
      <div
        key={indexProducts}
        className={`flex justify-center gap-2 flex-col sm:flex-row p-2 bg-white relative z-10 ${
          productImageCount === 1 ? "max-w-screen-md" : ""
        }`}
      >
        <span className="flex w-full h-full overflow-hidden rounded">{item.images}</span>
      </div>
    )
  })

  const heroImages = products
    .filter(item => item.images)
    .map((item, index) => {
      return (
        <div
          key={index}
          className={`relative flex w-full cursor-pointer hover:opacity-100  ${
            index === value
              ? "opacity-100 shadow-lg border-1 border-gray-500"
              : "opacity-50"
          }`}
          onClick={() => setValue(index)}
        >
          {item.images[0]}
          <div
            className={`absolute inset-0 px-2 py-1 text-sm text-center text-white bg-black bg-opacity-30 flex items-center justify-center uppercase tracking-widest md:text-2xl font-semibold ${
              index === value ? "flex" : "hidden"
            }`}
          >
            {item.name}
          </div>
          {productLength !== 1 && (
            <div
              className={`absolute inset-0 w-full h-full opacity-20 hover:opacity-0 ${
                index === value ? "opacity-0 bg-black bg-opacity-40" : ""
              }`}
              style={{ backgroundImage: `url(${index !== value ? dot : ""})` }}
            >
              &nbsp;
            </div>
          )}
          {productLength !== 1 && (
            <div
              className={`${
                index === value
                  ? "absolute inset-0 m-2 border-2 border-white hidden sm:flex"
                  : ""
              }  `}
            ></div>
          )}
        </div>
      )
    })

  return (
    <Layout>
      <SEO title={OthersText()} />
      <div className="flex flex-col justify-center w-full max-w-screen-lg mx-auto">
        <div className="relative flex object-cover w-full h-48 sm:h-96">
          {heroImages}
        </div>
        <div className="flex justify-center my-10 ">{content[value]}</div>
        <div className="flex justify-center mx-auto mb-20">
          {productImages[value]}
        </div>
      </div>
    </Layout>
  )
}

export default OtherProducts
